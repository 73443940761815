import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>Oh no!</h1>
    <p>You just found a page that doesn&#39;t exist...</p>
  </Layout>
)

export default NotFoundPage
